import DrawerWrapper from '@components/DrawerWrapper';
import UpgradeSuccess from '@components/UpdatePremiumAccount/Components/UpgradeSuccess';
import React, { useState } from 'react';
import { UpdateCompanyContext } from '@components/modals/UpgradeCompany/context';
import ModalWrapper from '@components/ModalWrapper';
import EnterInfo from '@components/modals/UpgradeCompany/components/EnterInfo';

type Props = {
  openModal?: string,
  setOpenModal?: Function,
};

export default function UpdateCompanyProfileMobile(props: Props) {
  const { openModal, setOpenModal } = props,
    [openModalSuccess, setOpenModalSuccess] = useState(false);
  return (
    <UpdateCompanyContext.Provider
      value={{
        openModal,
        setOpenModal,
        openModalSuccess,
        setOpenModalSuccess,
      }}>
      {openModal && (
        <DrawerWrapper open={openModal} onClose={() => setOpenModal(false)}>
          <div className="w-full flex" style={{ height: '100vh' }}>
            <EnterInfo />
          </div>
        </DrawerWrapper>
      )}
      {openModalSuccess && (
        <ModalWrapper
          className="rounded-rd8px max-w-95%"
          open={openModalSuccess}
          onClose={() => setOpenModalSuccess(false)}>
          <UpgradeSuccess close={() => setOpenModalSuccess(false)} />
        </ModalWrapper>
      )}
    </UpdateCompanyContext.Provider>
  );
}
